(function () {

    $(document).ready(function(){
        var params = (new URL(document.location)).searchParams;

        if (checkParam()) {
            $.cookie('utm-tracking', $(location).attr('href') , { expires: 1, path: '/' });
        }

        function checkParam() {
            return  params.get('utm_campaign') !== null && params.get('utm_source') !== null && params.get('utm_medium') !== null
        }
    });

})();
